import React from "react";
import { useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";



const photos = [
  //{ src: require("../../assets/photos/_DSC0036.jpg"), width: 3000, height: 2000 },
  //{ src: require("../../assets/photos/_DSC0191.jpg"), width: 3000, height: 2000 },
  //{ src: require("../../assets/photos/_DSC0233.jpg"), width: 3000, height: 2000 },
  { src: require("../../assets/photos/_DSC0277.jpg"), width: 3000, height: 2000 },
  //{ src: require("../../assets/photos/_DSC0294edit.jpg"), width: 3000, height: 2000 },
  { src: require("../../assets/photos/_DSC0347_02.jpg"), width: 3000, height: 2000 },
  { src: require("../../assets/photos/_DSC0375.jpg"), width: 3000, height: 2000 },
  { src: require("../../assets/photos/_DSC0432_01.jpg"), width: 3000, height: 2000 },
  { src: require("../../assets/photos/_DSC0478.jpg"), width: 3000, height: 2000 },
  { src: require("../../assets/photos/_DSC0629.jpg"), width: 3000, height: 2000 },
  { src: require("../../assets/photos/_DSC0662.jpg"), width: 3000, height: 2000 },
  { src: require("../../assets/photos/_DSC0535.jpg"), width: 2000, height: 2500 },
  { src: require("../../assets/photos/_DSC0666_03.jpg"), width: 2000, height: 2500 },
  { src: require("../../assets/photos/_DSC0684.jpg"), width: 2000, height: 2500 },
  { src: require("../../assets/photos/_DSC0699.jpg"), width: 3000, height: 2000 },
  { src: require("../../assets/photos/_DSC0817.jpg"), width: 2000, height: 2500 },
  { src: require("../../assets/photos/_DSC0912edit.jpg"), width: 3000, height: 2000 },
  { src: require("../../assets/photos/_DSC1439.jpg"), width: 3000, height: 2000 },
  { src: require("../../assets/photos/_DSC1557.jpg"), width: 3000, height: 2000 },
  { src: require("../../assets/photos/_DSC1559.jpg"), width: 2000, height: 3000 },
  { src: require("../../assets/photos/_DSC0007.jpg"), width: 2000, height: 3000 },
];

  
export default function GalleryCard() {
  const [index, setIndex] = useState(-1);
  return <>
  <PhotoAlbum layout="rows" photos={photos} targetRowHeight={600} onClick={({ index }) => setIndex(index)} />

  <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
      </>
}