import React from "react";
import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import "./pdfCard.scss"


import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();


  

export default function PdfCard(file, dark) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const pdf = file

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
      <div className="pdf-card" >
      <Document
        
        file={pdf}
        
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={pageNumber}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          />
      </Document>
      <div className="pdf-pageNumber">
        <p>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </p>
        <button className={dark? "pdf-button-dark" : "pdf-button"}
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          &lt;
        </button>
        <button className={dark? "pdf-button-dark" : "pdf-button"}
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          &gt;
        </button>
      </div>
    </div>
  );
}

