import React from "react";
import "./Gallery.scss";
import GalleryCard from "../../components/galleryCard/GalleryCard";
import { gallerySection } from "../../portfolio";

export default function Gallery() {
    if (gallerySection.display) {
        return (
          <div className="gallery-section" id="gallery">
            <h1 className="gallery-heading">Photo gallery</h1>
            <div className="gallery-card-container">
              <GalleryCard/>
            </div>
          </div>
        );
    }
    return null;
}