import React, {useContext} from "react";
import "./PDF.scss";
import PdfCard from "../../components/pdfCard/pdfCard";
import { PdfSection } from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

const pdfs = PdfSection.pdfs 

export default function PDF() {
    const {isDark} = useContext(StyleContext);
    if (PdfSection.display) {
        return (
          <div className="pdf-section" id="pdf">
            <Fade bottom duration={1000} distance="20px"></Fade>
              <h1 className="pdf-heading">Papers</h1>
              <div className="pdf-card-div">
              {pdfs.map((pdfFile) => (
              PdfCard(pdfFile, isDark)
              ))}
            </div>
          </div>
        );
    }
    return null;
}

